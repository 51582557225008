import { defineStore } from 'pinia'
import { TrackingPageType } from '~/types/pages/page-type.types'

export const usePageMetaStore = defineStore('pageMetaStore', () => {
  const campaignName: Ref<string | undefined> = ref('')
  const pageType: Ref<TrackingPageType | undefined> = ref(
    TrackingPageType.CmsPage
  )

  const setCampaignName = (name: string | undefined) => {
    campaignName.value = name
  }

  const setTrackingPageType = (key: TrackingPageType | undefined) => {
    pageType.value = key
  }

  return {
    campaignName,
    setCampaignName,
    pageType, // Todo: Change to trackingPageType to differentiate from pageType in routes
    setTrackingPageType,
  }
})
