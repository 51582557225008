export enum TrackingPageType {
  LandingPage = 'lp',
  CmsPage = 'cms',
}

export enum PageTypeName {
  Fallback = 'page',
  HomePage = 'home-page',
  BrandPage = 'brand-page',
  CategoryPage = 'category-page',
  LandingPage = 'landing-page',
}
